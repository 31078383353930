<template>
  <div>
    <template v-if="!editCard">
      <div>
        <span class="mr-1">Адреса:</span>
        <span class="font-weight-medium">
          {{ address.street }}, {{ address.building_number }}, {{ address.city }}, {{ address.country }}
        </span>
      </div>
      <div>
        <div class="mr-4 d-inline-block">
          <span class="mr-1">Координати:</span>
          <input id="coordinates" class="font-weight-medium" :value="`${address.latitude}, ${address.longitude}`" />
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon @click="copyText" v-bind="attrs" v-on="on" class="ml-2" size="18">mdi-content-copy</v-icon>
            </template>
            <span>Копіювати координати</span>
          </v-tooltip>
        </div>
      </div>
    </template>

    <template v-else>
      <div class="d-flex align-baseline">
        <span class="mr-1">Адреса:</span>
        <vue-google-autocomplete
          v-model="currentAddress"
          @placechanged="getAddressData"
          id="map"
          ref="toAddress"
          classname="map-control"
          placeholder="Почніть вводити адресу"
          country="ua"
        >
        </vue-google-autocomplete>
      </div>
      <!--      <div class="d-flex align-baseline">-->
      <!--        <div class="d-flex align-baseline mr-4">-->
      <!--          <span class="mr-1">Широта:</span>-->
      <!--          <v-text-field v-model.number="editedItem.latitude" dense hide-details></v-text-field>-->
      <!--        </div>-->
      <!--        <div class="d-flex align-baseline">-->
      <!--          <span class="mr-1">Довгота:</span>-->
      <!--          <v-text-field v-model.number="editedItem.longitude" dense hide-details></v-text-field>-->
      <!--        </div>-->
      <!--      </div>-->
    </template>

    <v-snackbar v-model="showSnackbar" timeout="1500" color="success" elevation="0" absolute bottom>
      <div class="text-center">Координати скопійовано</div>
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import VueGoogleAutocomplete from 'vue-google-autocomplete'
// import cloneDeep from '@/mixins/cloneDeep'

export default {
  name: 'CardAddress',

  components: { VueGoogleAutocomplete },

  // mixins: [cloneDeep],

  props: {
    /*currentItem: {
      type: Object,
      required: true,
      default: () => {},
    },*/
    type: {
      type: String,
      required: false,
      default: 'company',
    },
    entityId: {
      type: Number,
      required: false,
      default: null,
    },
    isDataSaved: {
      type: Boolean,
      required: true,
      default: false,
    },
    editCard: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      currentAddress: '',
      editedItem: {},
      showSnackbar: false,
    }
  },

  computed: {
    ...mapState('addresses', ['address']),

    currentCompanyId() {
      return this.$route.params.id
    },
  },

  created() {
    this.initialize()
  },

  watch: {
    isDataSaved(val) {
      if (val) this.saveData()
    },
    editCard(val) {
      if (!val) this.initialize()
    },
  },

  methods: {
    ...mapActions('addresses', ['updateAddress']),

    initialize() {
      // this.editedItem = this.cloneObjectDeep(this.currentItem)
      this.editedItem = { ...this.address }
      this.currentAddress = `${this.editedItem.street}, ${this.editedItem.building_number}, ${this.editedItem.city}, ${this.editedItem.country}`
    },
    copyText() {
      const text = document.getElementById('coordinates')
      text.select()
      navigator.clipboard.writeText(text.value)
      this.showSnackbar = true
      setTimeout(() => {
        text.blur()
      }, 1500)
    },
    getAddressData(addressData) {
      this.editedItem.country = addressData.country
      this.editedItem.city = addressData.locality
      this.editedItem.street = addressData.route
      this.editedItem.building_number = addressData.street_number
      this.editedItem.latitude = addressData.latitude
      this.editedItem.longitude = addressData.longitude
      this.currentAddress = `${addressData.route}, ${addressData.street_number}, ${addressData.locality}, ${addressData.country}`
    },
    async saveData() {
      const payload = {
        type: this.type,
        companyId: this.currentCompanyId,
        updatedAddress: this.editedItem,
        addressId: this.editedItem.id,
        servicePointId: this.type === 'servicePoint' ? this.entityId : null,
        partnerId: this.type === 'partner' ? this.entityId : null,
      }
      delete payload.updatedAddress.id
      await this.updateAddress(payload)
    },
  },
}
</script>

<style scoped lang="scss">
#coordinates {
  width: 200px;
  outline: none;
}
.map-control {
  width: 100%;
  outline: none;
  border-bottom: 1px solid #898b8c;
  height: 30px;
}
</style>
